<template>
  <div>
    <v-card class="d-flex justify-end mb-6" flat tile>
      <v-breadcrumbs :items="breadcrumbs" large>
        <template v-slot:divider>
          <v-icon>{{ icons.mdiSlashForward }} </v-icon>
        </template>
      </v-breadcrumbs>
    </v-card>
    <v-card flat class="pa-3 mt-2">
      <v-card-text>
        <v-card-title class="text-no-wrap pt-1 ps-2"> CREATE STATIC PAGE</v-card-title>
        <form @submit.prevent="CreateData" ref="form">
          <v-row>
            <v-col cols="12" v-for="error in errors" :key="error">
              <v-alert color="error" text class="mb-0">
                <div class="d-flex align-start">
                  <v-icon color="warning">
                    <!-- {{ icons.mdiAlertOutline }} -->
                  </v-icon>

                  <div class="ms-3">
                    <p class="text-base font-weight-medium mb-1">{{ error }}</p>
                    <!-- <a href="javascript:void(0)" class="text-decoration-none warning--text">
                    <span class="text-sm">Resend Confirmation</span>
                  </a> -->
                  </div>
                </div>
              </v-alert>
            </v-col>

            <v-col md="6" cols="12">
              <v-text-field label="Title" v-model="halstatis.judul_halaman" dense outlined></v-text-field>
            </v-col>

            <v-col md="12" cols="12">
              <textarea type="text" class="summernote" id="summernote" v-model="halstatis.isi_halaman"></textarea>
            </v-col>

            <v-col md="6" cols="12">
              <v-header>Aktif</v-header>
              <v-radio-group v-model="halstatis.aktif" column>
                <v-radio label="Yes" value="Y" color="info"></v-radio>
                <v-radio label="No" value="T" color="error"></v-radio>
              </v-radio-group>
            </v-col>

            <v-col cols="12">
              <v-btn type="submit" color="primary" class="me-3 mt-4"> Save </v-btn>

              <router-link :to="{ name: 'staticpage-index' }">
                <v-btn color="secondary" outlined class="mt-4" type="reset"> Cancel </v-btn>
              </router-link>
            </v-col>
          </v-row>
        </form>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import {
  mdiAccountOutline,
  mdiCurrencyUsd,
  mdiTrendingUp,
  mdiDotsVertical,
  mdiLabelOutline,
  mdiDelete,
  mdiGreasePencil,
  mdiContentSave,
  mdiSlashForward,
} from '@mdi/js'

export default {
  data() {
    return {
      breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/admin/dashboard',
        },
        {
          text: 'Static Page',
          disabled: false,
          href: '/admin/staticpage',
        },
        {
          text: 'Create Static Page',
          disabled: true,
        },
      ],

      // icons
      icons: {
        mdiGreasePencil,
        mdiDelete,
        mdiContentSave,
        mdiDotsVertical,
        mdiTrendingUp,
        mdiAccountOutline,
        mdiLabelOutline,
        mdiCurrencyUsd,
        mdiSlashForward,
      },

      halstatis: {
        judul_halaman: '',
        isi_halaman: '',
        aktif: 'Y',
      },
      errors: [],
    }
  },
  mounted() {
    this.$isLoading(true)
    setTimeout(() => {
      this.$isLoading(false)
    }, 2000)

    setTimeout(() => {
      $('.summernote').summernote({
        toolbar: [
          // [groupName, [list of button]]
          ['style', ['style']],
          ['font', ['bold', 'underline', 'clear']],
          ['fontname', ['fontname']],
          ['color', ['color']],
          ['para', ['ul', 'ol', 'paragraph']],
          ['table', ['table']],
          ['view', ['fullscreen', 'codeview', 'help']],
          ['highlight', ['highlight']],
        ],
        height: 300,
      })
      $('.summernote').summernote('code', this.halstatis.isi_halaman)
      this.$isLoading(false)
    }, 2000)
  },
  methods: {
    CreateData(e) {
      // console.log('NAMA ROLE : ' + this.halstatis.judul_halaman)
      if (this.$data.halstatis.judul_halaman.length === 0) {
        // this.errors.push('Title required !')
        this.$swal.fire({
          title: 'Error',
          text: 'Title required !',
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: false,
          timer: 1000,
        })
      } else {
        this.halstatis.isi_halaman = $('#summernote').summernote('code')

        let uri = process.env.VUE_APP_ROOT_API + '/api/halamanstatis/store'

        this.axios
          .post(uri, {
            judul_halaman: this.halstatis.judul_halaman,
            isi_halaman: this.halstatis.isi_halaman,
            aktif: this.halstatis.aktif,
          })
          .then(response => {
            this.$swal.fire({
              title: 'Success',
              text: 'Static page created successfully',
              icon: 'success',
              showCancelButton: false,
              showConfirmButton: false,
              timer: 2000,
            })
          })
          .catch(e => {
            // console.log(e);
            this.$swal.fire({
              title: 'Error!',
              text: 'Static page failed to create!',
              icon: 'error',
              showCancelButton: false,
              showConfirmButton: false,
              timer: 1000,
            })
          })

        e.preventDefault()
        setTimeout(() => {
          this.$router.push({ name: 'staticpage-index' })
        }, 3000)
      }
    },
  },
}
</script>
